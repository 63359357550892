import React , {useEffect , useRef} from "react";
import lottie from 'lottie-web';
import './Benefits.css';
import { Link } from "react-router-dom";

const Benefits = () => {

  const containerLottie1 = useRef(null)
  const containerLottie2 = useRef(null)
  const containerLottie3 = useRef(null)
  const containerLottie4 = useRef(null)
  const containerLottie5 = useRef(null)
  const containerLottie6 = useRef(null)
  const containerLottie7 = useRef(null)
  const containerLottie8 = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container : containerLottie1.current,
      renderer: 'svg',
      loop: true,
      autoplay:true,
      animationData: require('../../../assets/LottieAnimation/Animation - featherPen.json')
    })
    lottie.loadAnimation({
      container: containerLottie2.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - lightningBolt.json'),
    });
    lottie.loadAnimation({
      container: containerLottie3.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - teamCollab.json' ),
    });
    lottie.loadAnimation({
      container: containerLottie4.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - chat.json' ),
    });
    lottie.loadAnimation({
      container: containerLottie5.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - contract.json' ),
    });
    lottie.loadAnimation({
      container: containerLottie6.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - extraCost.json' ),
    });
    lottie.loadAnimation({
      container: containerLottie7.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation -  cursor.json' ),
    });
    lottie.loadAnimation({
      container: containerLottie8.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - projectRecap.json' ),
    });
  })

  return (
    <div className='benefits'>
      <div className='block'>
        <div className='benefits-container'>
          <div className='benefits-block__top'>
            <div className='text-eyebrow'>
              <svg
                className='svgsprite svg-s-24'
                xmlns='http://www.w3.org/2000/svg'
                width='100%'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M14 2H10V7.17157L6.34315 3.51472L3.51472 6.34314L7.17158 10H2V14H7.17157L3.51472 17.6568L6.34315 20.4853L10 16.8284V22H14V16.8284L17.6569 20.4853L20.4853 17.6569L16.8284 14H22V10H16.8284L20.4853 6.34314L17.6569 3.51471L14 7.17157V2Z'
                  fill='currentColor'
                ></path>
              </svg>
              <div className='pl-8 fw2'>
                Benefits of working with us <br />
              </div>
            </div>
            <div className='block-header block-header--modifier'>
              <h1 className=''>
                Get world-class
                <span className='new-line'>&nbsp;code, faster</span>
              </h1>
              <p className='font-s-p2'>
                Access all our development services for a flat monthly rate.
                <br />
                Benefit from all the advantages.
              </p>
            </div>
            <div className='mt-56 align-left-sm'>
              <Link
                to='https://wa.me/76390457'
                target='whatsapp'
                className='btn btn-cta cta-primary w-inline-block'
              >
                <div className='btn-cta__ripple'>
                  <span></span>
                </div>
                <div className='btn-cta__title'>
                  <span data-text='Get Started Today'>Get Started Today</span>
                </div>
              </Link>
            </div>
          </div>
          <div className='benefits-grid'>
            <div className='benefits-grid__item'>
              <div className='benefits-icon' ref={containerLottie1}></div>
              <div className='benefits-headline'>
                <h5>
                  <strong>Unlimited requests</strong>
                </h5>
              </div>
              <div className='benefits-subhead'>
                <div>Unlimited task requests, prioritized as you like.</div>
              </div>
            </div>
            <div className='benefits-grid__item'>
              <div className='benefits-icon' ref={containerLottie2}></div>
              <div className='benefits-headline'>
                <h5>
                  <strong>Fast turnaround</strong>
                </h5>
              </div>
              <div className='benefits-subhead'>
                <div>Tasks delivered in just 10-15 working business days.</div>
              </div>
            </div>
            <div className='benefits-grid__item'>
              <div className='benefits-icon' ref={containerLottie3}></div>
              <div className='benefits-headline'>
                <h5>
                  <strong>Your entire team</strong>
                </h5>
              </div>
              <div className='benefits-subhead'>
                <div>Busy? Invite your team to manage tasks together.</div>
              </div>
            </div>
            <div className='benefits-grid__item'>
              <div className='benefits-icon' ref={containerLottie4}></div>
              <div className='benefits-headline'>
                <h5>
                  <strong>Easy communication</strong>
                </h5>
              </div>
              <div className='benefits-subhead'>
                <div>Communicate for instant updates.</div>
              </div>
            </div>
            <div className='benefits-grid__item'>
              <div className='benefits-icon' ref={containerLottie5}></div>
              <div className='benefits-headline'>
                <h5>
                  <strong>No contracts</strong>
                </h5>
              </div>
              <div className='benefits-subhead'>
                <div>
                  No contracts. Stay if you love it, leave when you want!
                </div>
              </div>
            </div>
            <div className='benefits-grid__item'>
              <div className='benefits-icon' ref={containerLottie6}></div>
              <div className='benefits-headline'>
                <h5>
                  <strong>No extra Costs</strong>
                </h5>
              </div>
              <div className='benefits-subhead'>
                <div>
                  Get a stable price paid once with no surprises, just
                  transparency.
                </div>
              </div>
            </div>
            <div className='benefits-grid__item'>
              <div className='benefits-icon' ref={containerLottie7}></div>
              <div className='benefits-headline'>
                <h5>
                  <strong>Top-notch quality</strong>
                </h5>
              </div>
              <div className='benefits-subhead'>
                <div>
                  Access incredible design quality whenever you need it.
                </div>
              </div>
            </div>
            <div className='benefits-grid__item'>
              <div className='benefits-icon' ref={containerLottie8}></div>
              <div className='benefits-headline'>
                <h5>
                  <strong>Risk-free Revisions</strong>
                </h5>
              </div>
              <div className='benefits-subhead'>
                <div>Unlimited free revisions, at no extra cost.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
