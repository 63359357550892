import React, { useState } from 'react';
import weblogo from '../../assets/images/logoTransparent.png';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import './NavbarView.css';

export default function NavbarView() {
  /*=================change background header=============== */
  window.addEventListener('scroll', function () {
    const header = this.document.querySelector('.header');
    if (this.scrollY >= 80) header.classList.add('scroll-header');
    else header.classList.remove('scroll-header');
  });
  /*=============== Toggle Menu ===============*/
  const [toggle, setToggle] = useState(false);
  const [activeNav, setActiveNav] = useState('/');
  return (
    <header className='header'>
      <nav className='nav container'>
        <Link
          to='/'
          className='nav__logo header__title'
        >
          <img
            src={weblogo}
            alt=''
          />
        </Link>

        <div className={toggle ? 'nav__menu show-menu' : 'nav__menu'}>
          <ul className='nav__list '>
            <CustomLink
              to='/'
              setActiveNav={setActiveNav}
              activeNav={activeNav}
              setToggle={setToggle}
            >
              <i className='uil uil-estate nav__icon'></i>Home
            </CustomLink>
            <CustomLink
              to='/about-us'
              setActiveNav={setActiveNav}
              activeNav={activeNav}
              setToggle={setToggle}
            >
              <i className='uil uil-user nav__icon'></i>About Us
            </CustomLink>
            <CustomLink
              to='/projects'
              setActiveNav={setActiveNav}
              activeNav={activeNav}
              setToggle={setToggle}
            >
              <i className='uil uil-window nav__icon'></i>Projects
            </CustomLink>
            <CustomLink
              to='/websites'
              setActiveNav={setActiveNav}
              activeNav={activeNav}
              setToggle={setToggle}
            >
              <i className='uil uil-window nav__icon'></i>Websites
            </CustomLink>
            <CustomLink
              to='/contact-us'
              setActiveNav={setActiveNav}
              activeNav={activeNav}
              setToggle={setToggle}
            >
              <i className='uil uil-briefcase-alt nav__icon'></i>Contact Us
            </CustomLink>
          </ul>

          <i
            className='uil uil-times nav__close'
            onClick={() => setToggle(!toggle)}
          ></i>
        </div>

        <div
          className='nav__toggle'
          onClick={() => setToggle(!toggle)}
        >
          <i className='uil uil-apps'></i>
        </div>
      </nav>
    </header>
  );
}

function CustomLink({
  to,
  children,
  setActiveNav,
  activeNav,
  setToggle,
  ...props
}) {
  const resolvePath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvePath.pathname, end: true });

  const handleClick = () => {
    setActiveNav(to);
    setToggle(false);
  };
  return (
    <li className='nav__item'>
      <Link
        to={to}
        {...props}
        className={`nav__link ${isActive ? 'active' : ''}`}
        onClick={handleClick}
      >
        {children}
      </Link>
    </li>
  );
}
