import React, { useState, useEffect } from 'react';
import './Showreel.css';
import carousel1 from '../../../assets/Carousel/carousel1.webp';
import carousel2 from '../../../assets/Carousel/carousel2.jpg';
import carousel3 from '../../../assets/Carousel/carousel3.jpg';
import carousel4 from '../../../assets/Carousel/carousel4.jpg';

const Showreel = () => {
  const images = [ carousel1 ,carousel2, carousel3, carousel4];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 2000); // Change slide every 2000ms

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section  className="slick-slider">
    <div className='showreel showreelContainer container'>
      <div data-scroll-reveal="" className='showreel-container'>
        <div className='showreel-container__inner'>
          <div data-delay="500" data-animation="slide" className='slider no-select w-slider'>
            <div className='mask w-slider-mask'>
              {images.map((img, index) => (
                <div
                  key={index}
                  className={`slide w-slide ${index === currentSlide ? 'active' : ''}`}
                  aria-label={`${index + 1} of ${images.length}`}
                  role="group"
                >
                  <img src={img} alt="" className='showreel-img' />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Showreel;
