import Image1 from '../../../assets/images/eeeg.png';
import Image2 from '../../../assets/images/pointingDown.jpg';


export const Data = [
  {
    id: 1,
    image: Image1,
    title: 'EEE Group',
    description:
      'A really good job, all aspects of the project were followed step by step and with good results.',
  },
  {
    id: 2,
    image: Image2,
    title: 'Digital Space',
    description:
      'delivered a fantastic website that perfectly represents our brand. Highly recommend their professionalism!',
  },
  {
    id: 3,
    image: Image2,
    title: 'Nour N',
    description:
      'Our website truly reflects our brand thanks to their expertise. Highly recommended!',
  },
];
