import './WhatWeDo.css';
import laptop from '../../../assets/images/laptop.webp';

const WhatWeDo = () => {
  return (
    <div className='what-we-do '>
      <div className='what-we-do__block'>
        <div className='what-we-do__container'>
          <div className='what-we-do-block__top'>
            <div className='text-eyebrow'>
              <svg
                className='svgsprite svg-s-24'
                xmlns='http://www.w3.org/2000/svg'
                width='100%'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M14 2H10V7.17157L6.34315 3.51472L3.51472 6.34314L7.17158 10H2V14H7.17157L3.51472 17.6568L6.34315 20.4853L10 16.8284V22H14V16.8284L17.6569 20.4853L20.4853 17.6569L16.8284 14H22V10H16.8284L20.4853 6.34314L17.6569 3.51471L14 7.17157V2Z'
                  fill='currentColor'
                ></path>
              </svg>
              <div className='pl-8 fw2'>
                What do we do :
                <br />
              </div>
            </div>
            <div className='block-header block-header--modifier'>
              <h2 className='what-we-do__title'>
                A breakdown
                <br />
                of what we do
              </h2>
              <p className='font-s-p2'>
                <span className='new-line'>
                  Your go-to solution for web and mobile apps,{' '}
                </span>
                like many founders, startups, and agencies do.
              </p>
            </div>
          </div>

          <div className='what-we-do-block__bottom'>
            <svg
              className='svgsprite svg-arrow-down'
              xmlns='http://www.w3.org/2000/svg'
              width='100%'
              viewBox='0 0 102 110'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M50.7033 62.9562C50.5986 72.6396 47.6532 78.5514 43.9298 81.7563C40.2067 84.961 35.4143 85.7182 31.0205 84.5374C26.6095 83.352 22.7232 80.2416 20.8756 75.8688C19.0518 71.5525 19.1048 65.7341 23.088 58.8925C28.1466 50.2037 38.1867 45.3297 49.0246 44.8123C50.0429 50.1115 50.7788 55.9713 50.7033 62.9562ZM19.6311 56.88C25.4208 46.9356 36.4564 41.5813 48.2007 40.8645C48.149 40.6332 48.0971 40.4029 48.0449 40.1734C47.6845 38.5888 47.3071 37.0271 46.9324 35.4768C45.7803 30.7098 44.6543 26.0506 44.1297 21.1679C43.4815 15.1346 43.7311 8.6951 45.8341 0.931319L49.6949 1.97713C47.7297 9.23231 47.5084 15.1705 48.1068 20.7406C48.6011 25.341 49.6415 29.6487 50.7759 34.3452L50.776 34.3454C51.1608 35.9387 51.5565 37.5768 51.9453 39.2863C52.059 39.7865 52.1718 40.2914 52.283 40.8015C57.7823 40.9653 63.3216 42.1295 68.4471 44.2989C77.2685 48.0325 85.0304 54.7791 89.4827 64.6819C93.5588 73.7479 94.7917 85.3017 91.7254 99.3537C92.0394 99.1703 92.3591 99.0031 92.6832 98.8513C95.7831 97.3996 99.0891 97.4474 101.237 97.9321L100.356 101.834C98.8757 101.5 96.5082 101.477 94.3796 102.474C92.366 103.417 90.3678 105.358 89.5627 109.392L85.6401 108.609C86.4459 104.571 85.3639 101.894 83.8475 100.12C82.2583 98.2603 80.0647 97.255 78.5871 96.9215L79.4676 93.0197C81.6181 93.5049 84.6355 94.8851 86.8883 97.521C87.2025 97.8886 87.4996 98.2783 87.776 98.6902C90.7497 85.2207 89.5108 74.4991 85.8344 66.3221C81.8388 57.4349 74.8812 51.3656 66.888 47.9825C62.4539 46.1058 57.7506 45.0662 53.0972 44.8225C54.0769 50.1136 54.7785 56.0189 54.703 62.9995C54.59 73.4512 51.3864 80.6157 46.5393 84.7879C41.6918 88.9604 35.4923 89.8811 29.9824 88.4003C24.4897 86.9242 19.5606 83.0336 17.191 77.4256C14.7976 71.7612 15.1255 64.619 19.6311 56.88Z'
                fill='currentColor'
              ></path>
            </svg>
            <div className='work-areas'>
              <div>
                <h1 className='work-areas-list'>
                  <span className='work-areas-list__item skill-1'>
                    <div>Art Direction</div>
                  </span>
                  <span className='work-areas-list__item skill-2'>
                    <div className='svg_span-arrow'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='293'
                        height='51'
                        viewBox='0 0 293 51'
                        fill='none'
                        className='svg-wrap__img'
                      >
                        <path
                          d='M260.786 0.110596H272.559L292.354 25.0907L272.559 50.0709H260.786L269.983 38.493C272.632 35.1254 275.136 32.0371 277.564 29.2299H0.353516V20.8799H277.49C275.062 18.0047 272.559 14.9862 269.983 11.6885L260.786 0.110596Z'
                          fill='#7D7D87'
                        />
                      </svg>
                    </div>
                    <div>Design</div>
                  </span>
                  <span className='work-areas-list__item skill-3'>
                    <div>Interaction</div>
                    <div className='svg-wrap'>
                      <img
                        src='https://assets-global.website-files.com/65111bc3d36d02e461763c5f/65228e496a63b8b3ab4ba3e7_star.svg'
                        loading='lazy'
                        alt=''
                        class='svg-wrap__img'
                      />
                    </div>
                  </span>
                  <span className='work-areas-list__item skill-4'>
                    <div>Creative Dev</div>
                  </span>
                </h1>
              </div>
            </div>
            <div className='expertise'>
              <div className='expertise-list expertise-list--1'>
                <div className='expertise-list__img'>
                  <img
                    src='https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c6316ce6b39e59feb9f3c_skill-webapp.png'
                    loading='lazy'
                    sizes='(max-width: 479px) 100vw, (max-width: 991px) 48vw, 25vw'
                    srcset='https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c6316ce6b39e59feb9f3c_skill-webapp-p-500.png 500w, https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c6316ce6b39e59feb9f3c_skill-webapp.png 946w'
                    alt=''
                    class='skill-media-img'
                  />
                </div>
                <div className='expertise-list__content'>
                  <h5 className='card-info'>Web applications</h5>
                  <div className='text-adjected font-s-p1'>
                    We design and build sleek and responsive web apps for any
                    business challenge.
                  </div>
                </div>
              </div>
              <div className='expertise-list expertise-list--2'>
                <div className='expertise-list__img'>
                  <img
                    src='https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c6316ac63093c11784cc0_skill-mobileapp.png'
                    loading='lazy'
                    sizes='(max-width: 479px) 100vw, (max-width: 991px) 48vw, 25vw'
                    srcset='https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c6316ac63093c11784cc0_skill-mobileapp-p-500.png 500w, https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c6316ac63093c11784cc0_skill-mobileapp.png 946w'
                    alt=''
                    class='skill-media-img img-2'
                  />
                </div>
                <div className='expertise-list__content'>
                  <h5 className='card-info'>Mobile apps.</h5>
                  <div className='text-adjected font-s-p1'>
                    We design and develop, refine, and make your app shine above
                    the competition.
                  </div>
                </div>
              </div>
              <div className='expertise-list expertise-list--3'>
                <div className='expertise-list__img'>
                  <img
                    src='https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c63161f98ab28d6b23191_skill-designsystem.png'
                    loading='lazy'
                    sizes='(max-width: 479px) 100vw, (max-width: 991px) 48vw, 25vw'
                    srcset='https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c63161f98ab28d6b23191_skill-designsystem-p-500.png 500w, https://assets-global.website-files.com/65111bc3d36d02e461763c5f/659c63161f98ab28d6b23191_skill-designsystem.png 946w'
                    alt=''
                    class='skill-media-img img-3'
                  />
                </div>
                <div className='expertise-list__content'>
                  <h5 className='card-info'>Design system.</h5>
                  <div className='text-adjected font-s-p1'>
                    We build robust and cohesive design systems for easy
                    scalability.
                  </div>
                </div>
              </div>
              <div className='expertise-list expertise-list--4'>
                <div className='expertise-list__img'>
                  <img
                    src={laptop}
                    loading='lazy'
                    sizes='(max-width: 479px) 100vw, (max-width: 991px) 48vw, 25vw'
                    alt='latop'
                    class='skill-media-img img-4'
                  />
                </div>
                <div className='expertise-list__content'>
                  <h5 className='card-info'>Consulting</h5>
                  <div className='text-adjected font-s-p1'>
                    We'll tear apart your existing product and help you optimize
                    your product for better results.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
