import heroImage from '../../../assets/images/heroImage.jpg';
import { Link } from 'react-router-dom';
import './MainHome.css';

const MainHome = () => {
  return (
    <section className='main-Home'>
      <div className='home-container'>
        <div className='hero-header__content'>
          <div className='section-caption d-flex relative'>
            <div className='wave'>
              <div>👋</div>
            </div>
            <div className='wave-title'>
              It's Web ELite, Your product Development partner!
            </div>
          </div>
          <div className='font-s-h0'>
            <div className='hero-headline'>
              <div className='word'>Transform</div>
              <div className='word'>Your</div>
              <div className='word headline-img'>
                <span className='headline-span visible-md'>
                  <div className='headline-span__wrap is-rotated'>
                    <div className='image'>
                      <img
                        src={heroImage}
                        alt='hero img'
                      />
                    </div>
                  </div>
                </span>
              </div>
              <div className='word'>Online</div>
              <div className='word'>Presence</div>
              <div className='word'>with</div>
              <div className='headline-img'></div>
              <div className='word headline-img'>
                <span className='headline-span visible-md'>
                  <div className='headline-span__wrap'>
                    <div className='headline-icon-2 w-embed'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='currentColor'
                        viewBox='0 0 59 56'
                      >
                        <path
                          d='M26.87 0h10.2L58.5 28 37.069 56H26.87l9.959-12.977c4.142-5.401 7.09-9.177 8.843-11.326H.5v-7.472h45.173c-2.125-2.622-5.072-6.371-8.843-11.248L26.87 0Z'
                          class='â'
                        ></path>
                      </svg>
                    </div>
                  </div>
                </span>
              </div>
              <div className='word'>Endless</div>
              <div className='word'>Creativity</div>
            </div>
          </div>
          <div className='copy-body is-align-center'>
            <p className='hero-subhead font-s-p3'>
              <span>Skip the unpleasent hiring process and unpredictable costs.</span>
              &nbsp;Access high-quality web services on demand with fixed pricing.
              It's as simple as that!
              <br />
            </p>
          </div>
          <div className='btn-group mt-40'>
            <Link
              to='/websites'
              className='btn btn-cta cta-primary w-inline-block'
            >
              <div className='btn-cta__ripple'>
                <span></span>
              </div>
              <div className='btn-cta__title'>
                <span data-text='See plans'>See plans</span>
              </div>
            </Link>
            <Link
              to='/contact-us'
              className='btn btn-cta btn-soft w-inline-block'
            >
              <div className='btn-cta__ripple'>
                <span></span>
              </div>
              <div className='btn-cta__title'>
                <span data-text="Let's talk">Book a Call</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainHome;
