import React from 'react';
import Projects from '../../Sections/Projects/Projects';

export default function ProjectsPage() {
  return (
    <div>
      <Projects />
    </div>
  );
}
