import React, { useState } from 'react';
import laptopimg from '../../../assets/images/laptop.webp';
import './WebsitesPricing.css';

const WebsitesPricing = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  // Plan data including unique images for each card
  const plansData = {
    businessShop: [
      {
        title: 'Simple WEBSITE',
        subTitle:
          'Transform Your Online Identity with Our Comprehensive simple Website Package.',
        cost: '$200',
        features: [
          'Free hosting',
          '1-2 Pages',
          'Content writing',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '7 business days',
        imageURL: laptopimg,
      },
      {
        title: 'STANDARD WEBSITE',
        subTitle:
          'Unlock Your Web Potential with Our Engaging Website Solutions.',
        cost: '$350',
        features: [
          'Free domain name',
          'Free hosting',
          '5-10 Pages',
          'Content writing',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '15 business days',
        imageURL: laptopimg,
      },
      {
        title: 'CUSTOM WEBSITE',
        subTitle:
          'Elevate Your Brands Digital Footprint with Our Premier Custom Website Package.',
        cost: '$450',
        features: [
          'Free domain name',
          'Free hosting',
          '5-10 Custom Pages',
          'Content writing',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '20 business days',
        imageURL: laptopimg,
      },
    ],
    shop: [
      {
        title: 'SIMPLE SHOP',
        subTitle:
          'Kickstart Your E-commerce Journey with Our Efficient Simple Shop Package.',
        cost: '$350',
        features: [
          'Free domain name',
          'Free hosting',
          '5 Pages',
          'Basic Ecommerce Functionality',
          'Cash on Delivery',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '7 business days',
        imageURL: laptopimg,
      },
      {
        title: 'STANDARD SHOP',
        subTitle:
          'Boost Your E-commerce Presence with Our Robust Standard Shop Package.',
        cost: '$550',
        features: [
          'Free domain name',
          'Free hosting',
          'Standard Ecommerce Functionality',
          'Cash on Delivery + Card',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '15 business days',
        imageURL: laptopimg,
      },
      {
        title: 'CUSTOM SHOP',
        subTitle:
          'Maximize Your Online Business Impact with Our Customized Shop Package.',
        cost: '$650',
        features: [
          'Free domain name',
          'Free hosting',
          'Advanced Ecommerce Functionality',
          'Cash on Delivery + Card',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '20 business days',
        imageURL: laptopimg,
      },
    ],
    portfolio: [
      {
        title: 'ONE-PAGE PORTFOLIO',
        subTitle:
          'Present Your Best Work Seamlessly with Our Streamlined Portfolio Package.',
        cost: '$100',
        features: [
          'Free domain name',
          'Free hosting',
          'One Page',
          'Content writing',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '7 business days',
        imageURL: laptopimg,
      },
      {
        title: 'STANDARD PORTFOLIO',
        subTitle:
          'Elevate Your Professional Image with Our Enhanced Portfolio Package.',
        cost: '$150',
        features: [
          'Free domain name',
          'Free hosting',
          '3-5 Pages',
          'Content writing',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '10 business days',
        imageURL: laptopimg,
      },
      {
        title: 'CUSTOM PORTFOLIO',
        subTitle:
          'Showcase Your Excellence with Our Bespoke Digital Portfolio Package.',
        cost: '$250',
        features: [
          'Free domain name',
          'Free hosting',
          '3-5 Pages',
          'Content writing',
          'SEO-optimized',
          'Speed-optimized',
        ],
        deliveryTime: '15 business days',
        imageURL: laptopimg,
      },
    ],
  };

  return (
    <div className='c-pricing'>
      <div className='pricing__filled color-dark-green'>
        <div className='block'>
          <div className='pricing-container'>
            <div className='c-block__top'>
              <div className='text-eyebrow'>
                <svg
                  className='svgsprite svg-s-24'
                  xmlns='http://www.w3.org/2000/svg'
                  width='100%'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14 2H10V7.17157L6.34315 3.51472L3.51472 6.34314L7.17158 10H2V14H7.17157L3.51472 17.6568L6.34315 20.4853L10 16.8284V22H14V16.8284L17.6569 20.4853L20.4853 17.6569L16.8284 14H22V10H16.8284L20.4853 6.34314L17.6569 3.51471L14 7.17157V2Z'
                    fill='currentColor'
                  ></path>
                </svg>
                <div className='pl-8 fw2'>
                  Pricing
                  <br />
                </div>
              </div>
              <div className='block-header block-header--modifier'>
                <h2 className='txt-white'>
                  <span className='text_white'>Unlimited requests, </span>
                  <span className='new-line'>one simple fee. </span>
                </h2>
                <p className='font-s-p2 txt-secondary-inverse'>
                  Our pricing is simple and transparent, with no surprises or
                  limits. Get ongoing support whenever you need it.
                </p>
              </div>
            </div>
            <div className='pricing__tabs'>
              <div
                className={
                  toggleState === 1
                    ? 'pricing__button pricing__active button--flex'
                    : 'pricing__button button--flex'
                }
                onClick={() => toggleTab(1)}
              >
                <i className='uil uil-store pricing__icon'></i> Business
              </div>
              <div
                className={
                  toggleState === 2
                    ? 'pricing__button pricing__active button--flex'
                    : 'pricing__button button--flex'
                }
                onClick={() => toggleTab(2)}
              >
                <i className='uil uil-cube pricing__icon'></i> Shop
              </div>
              <div
                className={
                  toggleState === 3
                    ? 'pricing__button pricing__active button--flex'
                    : 'pricing__button button--flex'
                }
                onClick={() => toggleTab(3)}
              >
                <i className='uil uil-bag pricing__icon'></i>Protfolio
              </div>
            </div>
            <div className='pricing__sections'>
              {/* business sesction  */}
              <div
                className={
                  toggleState === 1
                    ? 'pricing__content pricing__content-active'
                    : 'pricing__content'
                }
              >
                {plansData.businessShop.map((plan, index) => (
                  <div
                    key={index}
                    className='pricing__data'
                  >
                    <div className='pricing-image__container'>
                      <img
                        src={plan.imageURL}
                        alt={`${plan.title} Plan`}
                        className='pricing-image'
                      />
                    </div>
                    <h3 className='pricing__title'>{plan.title}</h3>
                    <span className='pricing__sub'>{plan.subTitle}</span>
                    <span className='pricing__cost'>
                      <a
                        href='https://wa.me/76390457'
                        target='whatsapp'
                        className='whatsapp-link'
                      >
                        <i className='bx bxl-whatsapp pricing__icon'></i>
                        Claim it Now
                      </a>
                    </span>
                    <span className='pricing__cost'>
                      <i className='uil uil-store pricing__icon'></i>
                      {plan.cost}
                    </span>
                    <ul className='pricing__list'>
                      {plan.features.map((feature, idx) => (
                        <li key={idx}>
                          <i className='uil uil-check-circle checklist-icon'></i>
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <span className='pricing__time'>{plan.deliveryTime}</span>
                  </div>
                ))}
              </div>
              {/* shop plan section */}
              <div
                className={
                  toggleState === 2
                    ? 'pricing__content pricing__content-active'
                    : 'pricing__content'
                }
              >
                {plansData.shop.map((plan, index) => (
                  <div
                    key={index}
                    className='pricing__data'
                  >
                    <div className='pricing-image__container'>
                      <img
                        src={plan.imageURL}
                        alt={`${plan.title} Plan`}
                        className='pricing-image'
                      />
                    </div>
                    <h3 className='pricing__title'>{plan.title}</h3>
                    <span className='pricing__sub'>{plan.subTitle}</span>
                    <span className='pricing__cost'>
                      <a
                        href='https://wa.me/76390457'
                        target='whatsapp'
                        className='whatsapp-link'
                      >
                        <i className='bx bxl-whatsapp pricing__icon'></i>
                        Claim it Now
                      </a>
                    </span>
                    <span className='pricing__cost'>
                      <i className='uil uil-cube pricing__icon'></i>
                      {plan.cost}
                    </span>
                    <ul className='pricing__list'>
                      {plan.features.map((feature, idx) => (
                        <li key={idx}>
                          <i className='uil uil-check-circle checklist-icon'></i>
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <span className='pricing__time'>{plan.deliveryTime}</span>
                  </div>
                ))}
              </div>
              {/* Digital Portfolio*/}
              <div
                className={
                  toggleState === 3
                    ? 'pricing__content pricing__content-active'
                    : 'pricing__content'
                }
              >
                {plansData.portfolio.map((plan, index) => (
                  <div
                    key={index}
                    className='pricing__data'
                  >
                    <div className='pricing-image__container'>
                      <img
                        src={plan.imageURL}
                        alt={`${plan.title} Plan`}
                        className='pricing-image'
                      />
                    </div>
                    <h3 className='pricing__title'>{plan.title}</h3>
                    <span className='pricing__sub'>{plan.subTitle}</span>
                    <span className='pricing__cost'>
                      <a
                        href='https://wa.me/76390457'
                        target='whatsapp'
                        className='whatsapp-link'
                      >
                        <i className='bx bxl-whatsapp pricing__icon'></i>
                        Claim it Now
                      </a>
                    </span>
                    <span className='pricing__cost'>
                      <i className='uil uil-bag pricing__icon'></i>
                      {plan.cost}
                    </span>
                    <ul className='pricing__list'>
                      {plan.features.map((feature, idx) => (
                        <li key={idx}>
                          <i className='uil uil-check-circle checklist-icon'></i>
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <span className='pricing__time'>{plan.deliveryTime}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsitesPricing;
