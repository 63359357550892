import { useEffect, useCallback } from 'react';

const defaultMeta = {
  title: 'WebElite',
  description: "Discover WebElite's services including web development, SEO, and more.",
  keywords: 'web development, digital marketing, SEO',
  imageUrl: '%PUBLIC_URL%/android-chrome-512x512.png',  // Default image URL for social sharing
};

export function useMetaTags(page) {
  const updateMetaTags = useCallback((metaData) => {
    // Update standard meta tags
    updateMetaTag('description', metaData.description);
    updateMetaTag('keywords', metaData.keywords);

    // Update Open Graph Tags
    updateMetaTag('og:title', metaData.title, true);
    updateMetaTag('og:description', metaData.description, true);
    updateMetaTag('og:image', metaData.imageUrl, true);
    updateMetaTag('og:url', window.location.href, true);

    // Update Twitter Card Tags
    updateMetaTag('twitter:card', 'summary_large_image', true);
    updateMetaTag('twitter:title', metaData.title, true);
    updateMetaTag('twitter:description', metaData.description, true);
    updateMetaTag('twitter:image', metaData.imageUrl, true);
  }, []);

  useEffect(() => {
    const metaData = { ...defaultMeta };

    switch (page) {
      case 'HomePage':
        metaData.title = 'Home | WebElite';
        metaData.description = 'Discover top web development and professional SEO services at WebElite in Beirut. Boost your local business with our innovative digital solutions.';
        metaData.keywords = 'web development Beirut, SEO services Beirut, professional web development, Beirut digital agency';
        break;

      case 'AboutUsPage':
        metaData.title = 'About Us | WebElite';
        metaData.description = 'Learn more about WebElite and our mission to deliver quality web solutions. Meet our experienced web developers and discover our company values.';
        metaData.keywords = 'about WebElite, web developers Beirut, professional web design team, WebElite history, web development mission Beirut';
        break;

      case 'WebsitesPage':
        metaData.title = 'Our Websites | WebElite';
        metaData.description = 'Explore custom website services tailored to meet your business needs at WebElite. From eCommerce web design to business website development, we cover it all.';
        metaData.keywords = 'custom website services Beirut, eCommerce web design Beirut, business website development Beirut, responsive web design, online store development Beirut';
        break;

      case 'ContactUsPage':
        metaData.title = 'Contact Us | WebElite';
        metaData.description = 'Need assistance? Contact WebElite today for any inquiries or support needs related to our web services. Our support team is ready to help you succeed.';
        metaData.keywords = 'contact WebElite, web development help Beirut, support for web services, inquire about web services Beirut, WebElite support team';
        break;

      default:
        // Handle unexpected page keys or provide a generic meta setup
        console.log('No specific meta data setup for this page.');
        break;
    }

    console.log('Updating meta tags:', metaData);
    document.title = metaData.title;
    updateMetaTags(metaData);

  }, [page, updateMetaTags]); // Ensure updateMetaTags is included in the dependency array

  function updateMetaTag(name, content, isProperty = false) {
    let element = document.head.querySelector(
      isProperty ? `meta[property="${name}"]` : `meta[name="${name}"]`
    );
    if (!element) {
      element = document.createElement('meta');
      if (isProperty) {
        element.setAttribute('property', name);
      } else {
        element.setAttribute('name', name);
      }
      document.head.appendChild(element);
    }
    element.setAttribute('content', content);
  }
}
