import Benefits from '../../Sections/Benefits/Benefits';
import HowItWorks from '../../Sections/HowItWorks/HowItWorks';
import WebsitesPricing from '../../Sections/WebsitesPricing/WebsitesPricing';
import { useMetaTags } from '../../../Services/MetaDataService.ts';

const WebsitesPage = () => {
  useMetaTags('WebsitesPage');
  return (
    <div>
      <section>
        <Benefits />
      </section>
      <section>
        <HowItWorks />
      </section>
      <section>
        <WebsitesPricing />
      </section>
    </div>
  );
};

export default WebsitesPage;
