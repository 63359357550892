import React from 'react';
import NavbarView from '../../components/NavbarView/NavbarView';
import HomePage from '../MainPages/HomePage/HomePage';
import AboutUsPage from '../MainPages/AboutUsPage/AboutUsPage';
import WebsitesPage from '../MainPages/WebsitesPage/WebsitesPage';
import Footer from '../../components/Footer/Footer';
import ScrollUp from '../../components/ScrollUp/ScrollUp';
import ContactUsPage from '../MainPages/ContactUsPage/ContactUsPage';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import ProjectsPage from '../MainPages/Projects/ProjectsPage';

function App() {
  return (
    <>
      <NavbarView />
      <div>
        <Routes>
          <Route
            path='/'
            element={<HomePage />}
          />
          <Route
            path='/about-us'
            element={<AboutUsPage />}
          />
          <Route
            path='/projects'
            element={<ProjectsPage />}
          />
          <Route
            path='websites'
            element={<WebsitesPage />}
          />
          <Route
            path='contact-us'
            element={<ContactUsPage />}
          />
        </Routes>
      </div>
      <Footer />
      <ScrollUp />
    </>
  );
}

export default App;
