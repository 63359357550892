import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import './HowItWorks.css';

const HowItWorks = () => {
  const BenefitscontainerLottie1 = useRef(null);
  const BenefitscontainerLottie2 = useRef(null);
  const BenefitscontainerLottie3 = useRef(null);
  const BenefitscontainerLottie4 = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: BenefitscontainerLottie1.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - featherPen.json'),
    });
    lottie.loadAnimation({
      container: BenefitscontainerLottie2.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - lightningBolt.json'),
    });
    lottie.loadAnimation({
      container: BenefitscontainerLottie3.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation - projectRecap.json'),
    });
    lottie.loadAnimation({
      container: BenefitscontainerLottie4.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/LottieAnimation/Animation -  cursor.json'),
    });
  });

  return (
    <div className='how-it-works'>
      <div className='block'>
        <div className='how-it-works__container'>
          <div className='c-block__top'>
            <div className='text-eyebrow'>
              <svg
                className='svgsprite svg-s-24'
                xmlns='http://www.w3.org/2000/svg'
                width='100%'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M14 2H10V7.17157L6.34315 3.51472L3.51472 6.34314L7.17158 10H2V14H7.17157L3.51472 17.6568L6.34315 20.4853L10 16.8284V22H14V16.8284L17.6569 20.4853L20.4853 17.6569L16.8284 14H22V10H16.8284L20.4853 6.34314L17.6569 3.51471L14 7.17157V2Z'
                  fill='currentColor'
                ></path>
              </svg>
              <div className='pl-8 fw2'>
                How does it work?
                <br />
              </div>
            </div>
            <div className='block-header block-header--modifier'>
              <h2 >
                <span className='new-line2'>Our creative</span>
                <span className='new-line2'>process is simple and</span>
                <span className='new-line2'>straight-forward.</span>
              </h2>
            </div>
          </div>
          <div className='c-block__bottom'>
            <div className='work-areas'>
              <p class='font-s-p3 ff1'>
                <span class='highlight-line fw2 txt-primary'>
                  It's simple. You request, we design, Together!
                </span>
                We begin every project by a careful understanding of your needs.
                Then, we break down your project into hyper-focused, manageable
                requests. This approach ensures that each task receives the
                time, attention, and care it deserves.
              </p>
            </div>
            <div className='simple-step'>
              <div className='simple-step-block'>
                <div
                  className='simple-step-lottie'
                  ref={BenefitscontainerLottie1}
                ></div>
                <div class='simple-step-content'>
                  <h5 class='mb-16'>Initiate</h5>
                  <div class='fw2'>
                    Begin your web development journey with a comprehensive
                    strategy call.
                  </div>
                </div>
              </div>
              <div className='simple-step-block'>
                <div
                  className='simple-step-lottie'
                  ref={BenefitscontainerLottie2}
                ></div>
                <div class='simple-step-content'>
                  <h5 class='mb-16'>Submit</h5>
                  <div class='fw2'>
                    Send your requests, and we will bring your ideas to life
                  </div>
                </div>
              </div>
              <div className='simple-step-block'>
                <div
                  className='simple-step-lottie'
                  ref={BenefitscontainerLottie3}
                ></div>
                <div class='simple-step-content'>
                  <h5 class='mb-16'>Revise</h5>
                  <div class='fw2'>
                    Need more changes? We will revise your design until it's
                    perfect and matches your vision flawlessly.
                  </div>
                </div>
              </div>
              <div className='simple-step-block'>
                <div
                  className='simple-step-lottie'
                  ref={BenefitscontainerLottie4}
                ></div>
                <div class='simple-step-content'>
                  <h5 class='mb-16'>Grow</h5>
                  <div class='fw2'>
                    Watch your project grow with each completed request,
                    bringing your vision to life.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
