import Contact from "../../Sections/Contact/Contact";
import { useMetaTags } from "../../../Services/MetaDataService.ts";

const ContactUsPage = () => {
    useMetaTags('ContactUsPage');
    return(
        <section  className='section'>
            <Contact />
        </section>
    )
}

export default ContactUsPage;