import About from '../../Sections/About/About';
import Services from '../../Sections/Services/Services';
import Testimonials from '../../Sections/Testimonials/Testimonials';
import Showreel from '../../Sections/SlickSlider/Showreel';
import Faqs from '../../Sections/Faqs/Faqs';
import MainHome from '../../Sections/MainHome/MainHome';
import { useMetaTags } from '../../../Services/MetaDataService.ts';

const HomePage = () => {
  useMetaTags('HomePage');
  return (
    <div>
      <section>
        <MainHome />
      </section>
      <section>
        <Showreel />
      </section>
      <section>
        <About />
      </section>
      <section>
        <Services />
      </section>
      <section>
        <Testimonials />
      </section>
      <section>
        <Faqs />
      </section>
    </div>
  );
};

export default HomePage;
