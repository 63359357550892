import botanicaksa from '../../../assets/new-websites/BotanicaKsa.png';
import botanicaLb from '../../../assets/new-websites/BotanicaLb.png';
import dgSpace from '../../../assets/new-websites/DigitalSpace.png';
import eeeGroup from '../../../assets/new-websites/EEEGroup.png';
import ralphhalabi from '../../../assets/new-websites/Ralphhalabi.png';
import './Projects.css';

const Projects = () => {
  return (
    <section className='projects'>
      <div className='projects-block'>
        <div className='projects__container'>
          <div className='projects__top is--left'>
            <div className='projects__text-eyebrow'>
              <svg
                className='svgsprite svg-s-24'
                xmlns='http://www.w3.org/2000/svg'
                width='100%'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M14 2H10V7.17157L6.34315 3.51472L3.51472 6.34314L7.17158 10H2V14H7.17157L3.51472 17.6568L6.34315 20.4853L10 16.8284V22H14V16.8284L17.6569 20.4853L20.4853 17.6569L16.8284 14H22V10H16.8284L20.4853 6.34314L17.6569 3.51471L14 7.17157V2Z'
                  fill='currentColor'
                ></path>
              </svg>
              <div className='pl-8 fw2'>Work</div>
            </div>
            <div className='projects__block-header block-header--modifier'>
              <h2>
                Selected
                <br />
                work
              </h2>
              <p className='font-s-p2'>
                <span className='new-line'>
                  Whether it's a vibrant website or a minimal mobile app you
                  seek.
                </span>
                We deliver exceptional quality for all.
              </p>
            </div>
          </div>
          <div className='projects__bottom'>
            <div className='products_grid'>
              <div className='project-grid-item'>
                <div className='projects_grid-item'>
                  <a
                    href='https://ksa.botanica-boutique.com/'
                    target='botanica ksa'
                    className='project w-inline-block'
                  >
                    <div className='project-preview aspect-3'>
                      <div className='project-cover'>
                        <img
                          src={botanicaksa}
                          alt='project-p'
                          className='project-image style-show'
                        />
                      </div>
                    </div>
                    {/* <div className='project-text'>
                      <p className='project-text__title ff1'>
                        Ecommerce website
                      </p>
                      <p className='project-text__description'>hellp wrpd</p>
                    </div> */}
                  </a>
                </div>
                <div className='projects_grid-item'>
                  <a
                    href='https://www.ralphhalabi.com/'
                    className='project w-inline-block'
                    target='ralph halabi'
                  >
                    <div className='project-preview aspect-3'>
                      <div className='project-cover'>
                        <img
                          src={ralphhalabi}
                          alt='project-p'
                          className='project-image style-show'
                        />
                      </div>
                    </div>
                  </a>
                </div>
                <div className='projects_grid-item'>
                  <a
                    href='https://digitalspace.media/'
                    className='project w-inline-block'
                    target='digital space'
                  >
                    <div className='project-preview aspect-3'>
                      <div className='project-cover'>
                        <img
                          src={dgSpace}
                          alt='project-p'
                          className='project-image style-show'
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className='project-grid-item'>
                {/* <div className='projects_grid-item project-grid-empty'></div> */}
                <div className='projects_grid-item'>
                  <a
                    href='https://lb.botanica-boutique.com/'
                    className='project w-inline-block'
                    target='botanica lb'
                  >
                    <div className='project-preview aspect-3'>
                      <div className='project-cover'>
                        <img
                          src={botanicaLb}
                          alt='project-p'
                          className='project-image style-show'
                        />
                      </div>
                    </div>
                  </a>
                </div>
                <div className='projects_grid-item'>
                  <a
                    href='http://eee-group.net/'
                    className='project w-inline-block'
                    target='eeeGroup'
                  >
                    <div className='project-preview aspect-3'>
                      <div className='project-cover'>
                        <img
                          src={eeeGroup}
                          alt='project-p'
                          className='project-image style-show'
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='bottom-stripe'>
          <div className='btn-group flex-center'>
            <a
              href=''
              className='btn btn-cta btn-filled w-inline-block'
            >
              <div className='btn-cta__ripple'>
                <span></span>
              </div>
              <div className='btn-cta__title'>
                <span data-text='View on Figma'>&nbsp;View recent work</span>
              </div>
            </a>
            <a
              href=''
              className='btn btn-cta btn-filled w-inline-block'
            >
              <div className='btn-cta__ripple'>
                <span></span>
              </div>
              <div className='btn-cta__title'>
                <span data-text='View on Dribble'>&nbsp;View recent work</span>
              </div>
            </a>
          </div>
          <p className='short-para'>
            As you know, most of our work We can’t disclose publicly,
            <br />
            you know what I mean (under NDA)
          </p>
        </div> */}
      </div>
    </section>
  );
};

export default Projects;
