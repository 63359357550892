import React from 'react';
import AboutMe from '../../Sections/AboutMe/AboutMe';
import WhatWeDo from '../../Sections/WhatWeDo/WhatWeDo';
import { useMetaTags } from '../../../Services/MetaDataService.ts';

const AboutUsPage = () => {
  useMetaTags('AboutUsPage');
  return (
    <div>
      <section className='section'>
        <AboutMe />
      </section>
      <section>
        <WhatWeDo />
      </section>
    </div>
  );
};

export default AboutUsPage;
