import './Footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container container'>
        <h1 className='footer__title'>Web ELite</h1>

        <ul className='footer__list'>
          <li>
            <a
              href='/about-us'
              className='footer__link'
            >
              About Us
            </a>
          </li>

          <li>
            <a
              href='/websites'
              className='footer__link'
            >
              Websites
            </a>
          </li>

          <li>
            <a
              href='/contact-us'
              className='footer__link'
            >
              Contact Us
            </a>
          </li>
        </ul>

        <div className='footer__social'>
          <a
            href='https://www.instagram.com/webelite.lb?igsh=bTFuaXl3YWk3YWNr&utm_source=qr'
            className='footer__social-link'
            target={'ig'}
          >
            <i className='bx bxl-instagram'></i>
          </a>

          <a
            href='https://www.linkedin.com/company/webelitelb'
            className='footer__social-link'
            target={'linkedin'}
          >
            <i class='bx bxl-linkedin'></i>
          </a>
          <a
            href='https://wa.me/76390457'
            className='footer__social-link'
            target={'whatsapp'}
          >
            <i className='bx bxl-whatsapp'></i>
          </a>
        </div>

        <span className='footer__copy'>
          &#169; WebElite. All rigths reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
