import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ResetScroll () {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);  // This effect will run every time the pathname changes

  return null;  // This component does not render anything
}

export default ResetScroll;